import { Container, Grid, Paper, Typography, styled } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import PhoneIcon from '@mui/icons-material/Phone';
import EmailIcon from '@mui/icons-material/Email';
import { BrowserView, MobileView } from 'react-device-detect';
import FormComponent from './FormComponent';
import whatsapp from '../resources/WhatsAppButtonGreenLarge.png'


const Contact: React.FC = () => {
  const { t } = useTranslation();
  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#883447' : '#883447',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  }));
  return (

    <>
    <BrowserView>
      <Container id="contact" sx={{
        py: 10,
        textAlign: 'center', 
        minWidth: '40%',
        maxWidth: '100%'
      }}>
        <Typography variant="h3" sx={{ color: '#1C2F3C'}}><p><b>{t('contact_us')}</b></p></Typography>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid item xs={6}>
            <Item>
              <Typography variant="h5" px={5} sx={{ padding: '8px', color: '#ffffff'}}>{t('contact_details')}</Typography>
              <Typography variant='h6'  px={5} sx={{color: '#ffffff'}}> <PhoneIcon  style={{verticalAlign:"middle"}} />: <a href="tel:+393282422971">+393282422971</a></Typography>
              <Typography variant='h6'  px={5} sx={{color: '#ffffff'}}> <EmailIcon  style={{verticalAlign:"middle"}} />: <a href="mailto:mbpronto83@gmail.com">mbpronto83@gmail.com</a></Typography>
              <a aria-label="Chat on WhatsApp" href="https://wa.me/393282422971" target="_blank" rel="noopener noreferrer"> <img alt="Chat on WhatsApp" src={whatsapp} width={'50%'}/></a>
              <br></br>
              <br></br>
              <br></br>
              <Typography variant='h6'  px={5} sx={{color: '#ffffff'}}>{t('opening_hours')}</Typography>
              <br></br>
              <Typography variant='h6'  px={5} sx={{color: '#ffffff'}}>{t('monday_to_saturday')} </Typography>
              <Typography variant='h6'  px={5} sx={{color: '#ffffff'}}>{t('from_to')} </Typography>
            </Item>
          </Grid>
          <Grid item xs={6}>
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d715.9696843179423!2d12.4854191!3d44.1271233!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132cc7faa7fc3763%3A0x93d1101532fc3078!2sM.B.Pronto%20di%20Beshaj%20Eduart!5e0!3m2!1sen!2snl!4v1719175280658!5m2!1sen!2snl" width="90%" height="300" loading="lazy" title="map"></iframe>
          </Grid>
          <Grid item xs={12}>
            <FormComponent />
          </Grid>
        </Grid>
      </Container>
    </BrowserView>
    <MobileView>
      <Item sx={{width: '90%', margin: 'auto'}} id="contact">
        <Typography variant="h5" px={5} sx={{ padding: '8px', color: '#ffffff'}}>{t('contact_details')}</Typography>
        <Typography variant='h6'  px={5} sx={{color: '#ffffff'}}> <PhoneIcon  style={{verticalAlign:"middle"}} />: <a href="tel:+393282422971">+393282422971</a></Typography>
        <Typography variant='h6'  px={5} sx={{color: '#ffffff'}}> <EmailIcon  style={{verticalAlign:"middle"}} />: <a href="mailto:mbpronto83@gmail.com">mbpronto83@gmail.com</a></Typography>
        <a aria-label="Chat on WhatsApp" href="https://wa.me/393282422971" target="_blank" rel="noopener noreferrer"> <img alt="Chat on WhatsApp" src={whatsapp} width={'70%'}/></a>
        <br></br>
        <br></br>
        <Typography variant='h6'  px={5} sx={{color: '#ffffff'}}>{t('opening_hours')}</Typography>
        <br></br>
        <Typography variant='h6'  px={5} sx={{color: '#ffffff'}}>{t('monday_to_saturday')} </Typography>
        <Typography variant='h6'  px={5} sx={{color: '#ffffff'}}>{t('from_to')} </Typography>
        <br />
        <iframe style={{width: '90%', margin: 'auto'}} src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d715.9696843179423!2d12.4854191!3d44.1271233!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x132cc7faa7fc3763%3A0x93d1101532fc3078!2sM.B.Pronto%20di%20Beshaj%20Eduart!5e0!3m2!1sen!2snl!4v1719175280658!5m2!1sen!2snl" height="300" loading="lazy" title="map" ></iframe>
      </Item>
      <br></br>
      <FormComponent />


    </MobileView>
    </>

    
  );
};

export default Contact;