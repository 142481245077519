import { Container, ImageList, ImageListItem, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import GardenRenovation from './Projects/GardenRenovation';
import { BrowserView, MobileView } from 'react-device-detect';
import ProjectStaticData from '../data/ProjectStaticData';


const Projects: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container id="projects" sx={{
      py: 0,
      textAlign: 'center', 
      minWidth: '70%',
      maxWidth: '100%'
     }}>
      <Typography variant="h3" sx={{ color: '#1C2F3C'}}><p>{t('projects')}</p></Typography>
      <Typography variant="h5" px={5} sx={{ padding: '8px', color: '#1C2F3C'}}>{t('project_desc')}</Typography>
      <BrowserView>
        <ImageList variant="masonry" cols={4} gap={8}>
          {ProjectStaticData.mainObjects.map((mainObject) => (
            <ImageListItem style={{ cursor: 'pointer' }}>
              <GardenRenovation mainObject={mainObject} />
            </ImageListItem>
          ))}
        </ImageList>
      </BrowserView>
      
      <MobileView>
        <ImageList variant="masonry" cols={1} gap={0}>
          {ProjectStaticData.mainObjects.map((mainObject) => (
            <ImageListItem key={'Project1'} style={{ cursor: 'pointer' }}>
              <GardenRenovation mainObject={mainObject} />
            </ImageListItem>
          ))}
        </ImageList>
      </MobileView>

    </Container>
  );
};



export default Projects;