import { Card, CardContent, Container, Grid, ImageList, Rating, Typography } from '@mui/material';
import React from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

interface Review {
  name: string;
  stars: number;
  date: string;
  workType: string;
  review: string;
}

const reviews: Review[] = [
  {
    name: 'Lorella R.',
    date: '06 set 2021',
    stars: 5,
    workType: 'reviews.lorella.workType',
    review: 'reviews.lorella.review',
  },
  {
    name: 'Michele F.',
    date: '05 mag 2021',
    stars: 5,
    workType: 'reviews.michele.workType',
    review: 'reviews.michele.review',
  },
  {
    name: ' Lorenzo G.',
    date: '28 feb 2021',
    stars: 5,
    workType: 'reviews.lorenzo.workType',
    review: 'reviews.lorenzo.review',
  },
  {
    name: 'Zammarchi L.',
    date: '12 feb 2021',
    stars: 5,
    workType: 'reviews.zamarchi.workType',
    review: 'reviews.zamarchi.review',
  },
  {
    name: 'Marco T.',
    date: '07 oct 2020',
    stars: 5,
    workType: 'reviews.marco.workType',
    review: 'reviews.marco.review',
  },
  {
    name: 'Rossano D.',
    date: '5 sep 2020',
    stars: 5,
    workType: 'reviews.rossano.workType',
    review: 'reviews.rossano.review',
  },
  {
    name: 'Danilo S.',
    date: '28 jul 2020',
    stars: 5,
    workType: 'reviews.danilo.workType',
    review: 'reviews.danilo.review',
  },
  {
    name: 'Natalina D.',
    date: '10 may 2020',
    stars: 5,
    workType: 'reviews.natalina.workType',
    review: 'reviews.natalina.review',
  },
  {
    name: 'Lorenzo D.',
    date: '25 sep 2019',
    stars: 5,
    workType: 'reviews.lorenzoD.workType',
    review: 'reviews.lorenzoD.review',
  },
  {
    name: 'Rosy S.',
    date: '04 jul 2019',
    stars: 5,
    workType: 'reviews.rosy.workType',
    review: 'reviews.rosy.review',
  },
  {
    name: 'Chiara G.',
    date: '04 jul 2018',
    stars: 5,
    workType: 'reviews.chiara.workType',
    review: 'reviews.chiara.review',
  },
  {
    name: 'Michele C.',
    date: '29 jan 2018',
    stars: 5,
    workType: 'reviews.michele2.workType',
    review: 'reviews.michele2.review',
  },
  {
    name: 'Andrea V.',
    date: '08 aug 2018',
    stars: 5,
    workType: 'reviews.andrea.workType',
    review: 'reviews.andrea.review',
  },
];

const Testimonials: React.FC = () => {
  const { t } = useTranslation();

  return (
    <>
      <BrowserView>
        <Container id="testimonials" sx={{
          py: 10,
          textAlign: 'center',
          minWidth: '70%',
          maxWidth: '100%'
        }}>
          <Typography variant="h3" sx={{ color: '#1C2F3C' }}><p><b>{t('testimonials')}</b></p></Typography>
          <Typography variant="h4" px={5} sx={{ padding: '8px', color: '#1C2F3C' }}>{t('testimonialsDescription')}</Typography>

          <ImageList variant="masonry" cols={4} gap={8}>
            {reviews.map((review, index) => (
              <Card key={index} sx={{ width: 'auto', backgroundColor: '#883447', margin: '20px' }}>
                <CardContent>
                  <Typography variant="h6" component="div" color="#ffffff">
                    {review.name}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="#ffffff">
                    {review.date}
                  </Typography>
                  <Rating name="read-only" value={review.stars} readOnly />
                  <Typography sx={{ mb: 1.5 }} color="#ffffff">
                    {t(review.workType)}
                  </Typography>
                  <Typography variant="body2" color="#ffffff">
                    {t(review.review)}
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </ImageList>
        </Container>
      </BrowserView>
      <MobileView>
        <Container id="testimonials" sx={{
          py: 10,
          textAlign: 'center',
          maxWidth: '100%'
        }}>
          <Typography variant="h3" gutterBottom align="center" sx={{ padding: '8px', color: '#1C2F3C' }}>
            {t('testimonials')}
          </Typography>
          <Grid container spacing={2} justifyContent="center" sx={{ minWidth: '70%', maxWidth: '100%' }} >
            <Grid item xs={12} md={6}>
              <Typography variant="h5" px={5} sx={{ padding: '8px', color: '#1C2F3C' }}>
                {t('testimonialsDescription')}
              </Typography>
            </Grid>
          </Grid>

          <ImageList variant="masonry" cols={2} gap={1}>
            {reviews.map((review, index) => (
              <Card key={index} sx={{ width: 'auto', backgroundColor: '#883447', margin: '3px' }}>
                <CardContent>
                  <Typography variant="h6" component="div" color="#ffffff">
                    {review.name}
                  </Typography>
                  <Typography sx={{ mb: 1.5 }} color="#ffffff">
                    {review.date}
                  </Typography>
                  <Rating name="read-only" value={review.stars} readOnly />
                  <Typography sx={{ mb: 1.5 }} color="#ffffff">
                    {t(review.workType)}
                  </Typography>
                  <Typography variant="body2" color="#ffffff">
                    {t(review.review)}
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </ImageList>
        </Container>
      </MobileView>
    </>

  );
};

export default Testimonials;