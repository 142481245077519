import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  components: {
    MuiTextField: {
      styleOverrides: {
        root: {
          '& label': {
            color: 'white',
          },
          '& input': {
            color: 'white',
          },
          '& .MuiOutlinedInput-root': {
            '& fieldset': {
              borderColor: 'white',
            },
            '&:hover fieldset': {
              borderColor: 'white',
            },
            '&.Mui-focused fieldset': {
              borderColor: 'white',
            },
          },
        },
      },
    },
  },
  typography: {
    fontFamily: 'Black Ops One', // fontFamily: 'Fascinate' , fontFamily: 'Satisfy' , fontFamily: 'Emblema One' 
  },
  palette: {
    primary: {
      main: '#2b2e38'
    },
    secondary: {
      main: '#ffffff'
    }
  }
});

export default theme;

// Add this line to treat the file as a module
export {};