import React from 'react';
import Intro from './components/Intro';
import About from './components/About';
import Projects from './components/Projects';
import Contact from './components/Contact';
import Footer from './components/Footer';
import './App.css';
import Navbar from './components/Navbar';
import { BrowserView, MobileView } from 'react-device-detect';
import Testimonials from './components/Testimonials';


const App: React.FC = () => {
  return (
    <div className="App">
      <BrowserView>
        <div className='Nav'>
          <Navbar />
        </div>
      </BrowserView>
      <MobileView>
        <div className='NavMobile'>
          <Navbar />
        </div>
      </MobileView>
      <Intro />
      <About />
      <Projects />
      <Testimonials />
      <Contact />
      <Footer />
    </div>
  );
};

export default App;