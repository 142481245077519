import { Container, Grid, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import about_us_pic from '../resources/about_us.png'
import { BrowserView, MobileView } from 'react-device-detect';

const About: React.FC = () => {
  const { t } = useTranslation();
  return (
    <Container id="about" sx={{
      py: 0,
      textAlign: 'center',
      textJustify: 'center',
      minWidth: '70%',
      maxWidth: '100%',
      justifyContent: "center",
      alignItems: "center",
    }}>
      <BrowserView>
        <Grid container spacing={2} columns={2} sx={{
          textAlign: 'center',
          textJustify: 'center',
          justifyContent: "center",
          alignItems: "center"
        }}>
          <Grid item xs={1}>
            <img src={about_us_pic} alt='about us' style={{ width: '90%', marginRight: '20%' }} />
          </Grid>
          <Grid item xs={1}>
            <Typography variant="h4" px={5} sx={{ padding: '8px', color: '#1C2F3C' }}>
              <section>
                <Typography variant="h4" sx={{ color: '#1C2F3C'}}><h2>{t('aboutUs.story.heading')}</h2></Typography>
                <Typography variant="h3" sx={{ color: '#1C2F3C'}}><p>{t('aboutUs.story.text')}<b>{t('aboutUs.story.text2')}</b>{t('aboutUs.story.text3')}</p></Typography>
                
              </section>
            </Typography>          
          </Grid>
        </Grid>
        <section>
                <Typography variant="h4" sx={{ color: '#1C2F3C'}}><h2>{t('aboutUs.values.heading')}</h2></Typography>
                <Typography variant="h3" sx={{ color: '#1C2F3C'}}>
                <ul style={{ textAlign: 'left' }}>
                  <li style={{paddingBottom: '20px'}}><b>{t('aboutUs.values.integrityBold')}</b>{t('aboutUs.values.integrity')}</li>
                  <li style={{paddingBottom: '20px'}}><b>{t('aboutUs.values.qualityBold')}</b>{t('aboutUs.values.quality')}</li>
                  <li style={{paddingBottom: '20px'}}><b>{t('aboutUs.values.customerSatisfactionBold')}</b>{t('aboutUs.values.customerSatisfaction')}</li>
                  <li style={{paddingBottom: '20px'}}><b>{t('aboutUs.values.sustainabilityBold')}</b>{t('aboutUs.values.sustainability')}</li>
                </ul>
                </Typography>
              </section>
        <Typography variant="h4" sx={{ color: '#883447'}}><p><b>{t('aboutUs.conclusion')}</b></p></Typography>
      </BrowserView>
      <MobileView>
        <Grid container spacing={0} columns={1} sx={{
          textAlign: 'center',
          textJustify: 'center',
          justifyContent: "center",
          alignItems: "center"
        }}>
          <Grid item xs={1}>
            <Typography variant="h6" px={0} sx={{ padding: '0px', color: '#1C2F3C' }}>
              <h2>{t('aboutUs.story.heading')}</h2>
              <section style={{
              }}>
                <img src={about_us_pic} alt='about us' style={{ width: '50%', float: 'left', padding: '0px' }} />
                
                <p style={{textAlign: "center"}}>{t('aboutUs.story.text')}<b>{t('aboutUs.story.text2')}</b>{t('aboutUs.story.text3')}</p>
              </section>
              <section>
                <h2>{t('aboutUs.values.heading')}</h2>
                <ul style={{ textAlign: 'left' }}>
                  <li><b>{t('aboutUs.values.integrityBold')}</b>{t('aboutUs.values.integrity')}</li>
                  <li><b>{t('aboutUs.values.qualityBold')}</b>{t('aboutUs.values.quality')}</li>
                  <li><b>{t('aboutUs.values.customerSatisfactionBold')}</b>{t('aboutUs.values.customerSatisfaction')}</li>
                  <li><b>{t('aboutUs.values.sustainabilityBold')}</b>{t('aboutUs.values.sustainability')}</li>
                </ul>
              </section>
              <p style={{color: '#883447'}}><i><b>{t('aboutUs.conclusion')}</b></i></p>
            </Typography>
          </Grid>
        </Grid>
      </MobileView>
    </Container>
  );
};

export default About;