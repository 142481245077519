import { Box, Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Container, ImageList, ImageListItem, Modal, Typography } from '@mui/material';
import React from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
import { ProjectCardData } from '../../data/ProjectStaticData';
import { useTranslation } from 'react-i18next';

const style = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '50%',
	bgcolor: '#2b2e38',
	boxShadow: 24,
	overflow: 'auto',
	height: '85%',
	display: 'block',
	textAlign: 'center',
};

const styleMobile = {
	position: 'absolute' as 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: '90%',
	bgcolor: '#883447',
	boxShadow: 24,
	p: 4,
	overflow: 'scroll',
	height: '80%',
	display: 'block',
	textAlign: 'center',
};

interface MainObjectProps {
	mainObject: ProjectCardData;
}


const GardenRenovation: React.FC<MainObjectProps> = ({ mainObject }) => {
	const { t } = useTranslation();
	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);



	return (
		<Container sx={{
			py: 1,
			textAlign: 'center',
			minWidth: '70%',
			maxWidth: '100%'
		}}>
			<Card sx={{ maxWidth: 345, backgroundColor: '#883447' }}>
				<CardActionArea>
					<CardMedia
						component="img"
						height="280"
						image={mainObject.image}
						alt={mainObject.title}
					/>
					<CardContent>
						<Typography gutterBottom variant="h5" component="div" color='#ffffff'>
							{t(mainObject.title)}
						</Typography>
						<Typography variant="body2" color='#ffffff'>
							{t(mainObject.desc)}
						</Typography>
					</CardContent>
				</CardActionArea>
				<CardActions>
					<Button onClick={handleOpen} size="small" style={{ margin: "auto", color: '#ffffff' }}>
						{t('see_more_details')}
					</Button>
				</CardActions>
			</Card>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<>
					<BrowserView>
						<Box sx={style}>

							<Typography variant="h5" color='#ffffff' paddingTop='5%'>{t(mainObject.title)} <Button onClick={handleClose}><CloseFullscreenIcon sx={{ color: 'white' }} /></Button></Typography>
							<ImageList cols={2}>
								{mainObject.projectModalData.map((item) => (
									<ImageListItem>
										<Card sx={{ backgroundColor: '#883447', margin: '5%' }}>
											{item.video ?						
												<CardMedia
													component="iframe"
													src={item.image}
													height="400"
													width="300"
												/>											
												:
												<CardMedia
													component="img"
													alt="pic1"
													src={item.image}
												/>
											}
											<CardContent>
												<Typography variant="body1" color='#ffffff'>{t(item.text)}</Typography>
											</CardContent>
										</Card>
									</ImageListItem>

								))}
							</ImageList>
						</Box>
					</BrowserView>

					<MobileView>
						<Box sx={styleMobile}>
							<Typography variant="h5" color='#ffffff' paddingTop='5%'>{t(mainObject.title)} <Button onClick={handleClose}><CloseFullscreenIcon sx={{ color: 'white' }} /></Button></Typography>
							<ImageList cols={1}>
								{mainObject.projectModalData.map((item) => (
									<Card sx={{ maxWidth: '100%', backgroundColor: '#883447', margin: '10%' }}>
										{item.video ?						
												<CardMedia
													component="iframe"
													src={item.image}
													height="400"
													width="300"
												/>											
												:
												<CardMedia
													component="img"
													alt="pic1"
													src={item.image}
												/>
										}
										<CardContent>
											<Typography variant="body1" color='#ffffff' >{t(item.text)}</Typography>
										</CardContent>
									</Card>
								))}
							</ImageList>
						</Box>
					</MobileView>
				</>
			</Modal>



		</Container>
	);
};



export default GardenRenovation;