import React from 'react';
import { useTranslation } from 'react-i18next';
import LanguageSwitcher from './LanguageSwitcher';
import { BrowserView, MobileView } from 'react-device-detect';
import { AppBar, Box, Button, Toolbar, Typography } from '@mui/material';




const Navbar: React.FC = () => {
  const { t } = useTranslation();
  return (
    <>

      <BrowserView>
        <Box sx={{ flexGrow: 1 }}>
          <AppBar
            position="static"
            sx={{ backgroundColor: '#883447', borderRadius: '25px' }}
          >
            <Toolbar>
             
              <Typography variant="h6" component="div" sx={{ flexGrow: 1, color: '#ffffff' }}>M.B.Pronto di Beshaj Eduart & C. S.A.S</Typography>
              <Button href="#intro" color='inherit'> <Typography variant="body1" component="div" sx={{ flexGrow: 1, color: '#ffffff' }}>Intro</Typography></Button>
              <Button href="#about" color='inherit'> <Typography variant="body1" component="div" sx={{ flexGrow: 1, color: '#ffffff' }}>{t('about_us')}</Typography></Button>
              <Button href="#projects" color='inherit'> <Typography variant="body1" component="div" sx={{ flexGrow: 1, color: '#ffffff' }}>{t('projects')}</Typography></Button>
              <Button href="#contact" color='inherit'> <Typography variant="body1" component="div" sx={{ flexGrow: 1, color: '#ffffff' }}>{t('contact')}</Typography></Button>
              <LanguageSwitcher />
            </Toolbar>
          </AppBar>
        </Box>
      </BrowserView>


      <MobileView>
        <AppBar position="static" style={{ backgroundColor: '#883447'}}>
          <Toolbar>
            
            
            <Typography variant="body1" component="div" sx={{ flexGrow: 1, color: '#ffffff' }}>
              M.B.Pronto di Beshaj <br /> Eduart & C. S.A.S
            </Typography>
            <LanguageSwitcher />

          </Toolbar>

          
        </AppBar>
        
        
        
      </MobileView>

    </>

  );
};

export default Navbar;

/**           <Toolbar sx={{position: 'sticky'}}>
            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              sx={{ mr: 5 }}
            >
            </IconButton>
            <Button href="#about" color='inherit'> <Typography variant="body2" component="div" sx={{ flexGrow: 1, color: '#ffffff' }}>{t('about_us')}</Typography></Button>
            <Button href="#projects" color='inherit'> <Typography variant="body2" component="div" sx={{ flexGrow: 1, color: '#ffffff' }}>{t('projects')}</Typography></Button>
            <Button href="#contact" color='inherit'> <Typography variant="body2" component="div" sx={{ flexGrow: 1, color: '#ffffff' }}>{t('contact')}</Typography></Button>
          </Toolbar>
          */