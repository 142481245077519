import { Container, Typography } from '@mui/material';
import React from 'react';
import { BrowserView, MobileView } from 'react-device-detect';
import { useTranslation } from 'react-i18next';
import logo from '../resources/logo.png'

const Intro: React.FC = () => {
  const { t } = useTranslation();

  return (

    <>
      <BrowserView>
        <Container id="intro" sx={{
          paddingTop: 15,
          textAlign: 'center',
          minWidth: '70%',
          maxWidth: '100%'
        }}>
          <img src={logo} alt="Logo" style={{ height: '200px' }} />
          <Typography variant="h1" px={5} sx={{ color: '#1C2F3C', fontFamily: 'Black Ops One' }}> {t('intro')} </Typography>
        </Container>
      </BrowserView>

      <MobileView>
        <Container id="intro" sx={{
          paddingTop: 5,
          textAlign: 'center',
          minWidth: '70%',
          maxWidth: '100%'
        }}>
          <img src={logo} alt="Logo" style={{ height: '100px' }} />
          <Typography variant="h3" sx={{ color: '#1C2F3C', fontFamily: 'Black Ops One' }}> {t('intro')} </Typography>
        </Container>

      </MobileView>
    </>


  );
};

export default Intro;