import React, { useState, ChangeEvent, FormEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField, Button, Box, Typography, List, ListItem, IconButton, Container, Card } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';

interface FormData {
  email: string;
  name: string;
  address: string;
  phoneNumber: string;
  description: string;
  files: File[];
}

const FormComponent: React.FC = () => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<FormData>({
    email: '',
    name: '',
    address: '',
    phoneNumber: '',
    description: '',
    files: [],
  });
  const [fileNames, setFileNames] = useState<string[]>([]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newFilesArray = Array.from(e.target.files);
      setFormData((prevState) => ({
        ...prevState,
        files: [...prevState.files, ...newFilesArray],
      }));
      setFileNames((prevFileNames) => [
        ...prevFileNames,
        ...newFilesArray.map((file) => file.name),
      ]);
    }
  };

  const handleDeleteFile = (index: number) => {
    setFormData((prevState) => {
      const newFiles = prevState.files.filter((_, i) => i !== index);
      return {
        ...prevState,
        files: newFiles,
      };
    });
    setFileNames((prevFileNames) => prevFileNames.filter((_, i) => i !== index));
  };

  const handleSubmit = async (e: FormEvent) => {
    e.preventDefault();
    const form = new FormData();
    form.append('email', formData.email);
    form.append('name', formData.name);
    form.append('address', formData.address);
    form.append('phoneNumber', formData.phoneNumber);
    form.append('description', formData.description);
    formData.files.forEach((file) => {
      form.append('files', file);
    });

    try {
      const response = await fetch('https://edi-be.vercel.app/api/send-email', {
        method: 'POST',
        body: form,
      });
      if (response.ok) {
        alert(t('emailSent'));
      } else {
        alert(t('emailFailed'));
      }
    } catch (error) {
      console.error('Error:', error);
      alert(t('emailError'));
    }
  };

  return (
    <Container sx={{
      py: 1,
      textAlign: 'center',
      minWidth: '70%',
      maxWidth: '100%'
    }}>
      <Card sx={{ width: 'auto', backgroundColor: '#883447', margin: '5%', padding: '5%' }}>
        <Box
          component="form"
          onSubmit={handleSubmit}
          sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
        >
          <TextField
            label={t('email')}
            name="email"
            type="email"
            value={formData.email}
            onChange={handleChange}
            required
            color='secondary'
            sx={{ width: '100%', mb: 2 }}
          />
          <TextField
            label={t('name')}
            name="name"
            type="text"
            value={formData.name}
            onChange={handleChange}
            required
            color='secondary'
            sx={{ width: '100%', mb: 2 }}
          />
          <TextField
            label={t('address')}
            name="address"
            type="text"
            value={formData.address}
            onChange={handleChange}
            required
            color='secondary'
            sx={{ width: '100%', mb: 2 }}
          />
          <TextField
            label={t('phoneNumber')}
            name="phoneNumber"
            type="tel"
            value={formData.phoneNumber}
            onChange={handleChange}
            required
            color='secondary'
            sx={{ width: '100%', mb: 2 }}
          />
          <TextField
            label={t('description')}
            name="description"
            type="text"
            value={formData.description}
            onChange={handleChange}
            multiline
            rows={4}
            required
            color='secondary'
            sx={{ width: '100%', mb: 2 }}
          />
          <Button
            variant="contained"
            component="label"
            sx={{ mb: 2 }}
          >
            {t('attachFile')}
            <input
              type="file"
              multiple
              hidden
              onChange={handleFileChange}
            />
          </Button>
          {fileNames.length > 0 && (
            <List sx={{ width: '100%' }}>
              {fileNames.map((fileName, index) => (
                <ListItem key={index} secondaryAction={
                  <IconButton edge="end" aria-label="delete" onClick={() => handleDeleteFile(index)}>
                    <DeleteIcon />
                  </IconButton>
                }>
                  <Typography variant="body2">{fileName}</Typography>
                </ListItem>
              ))}
            </List>
          )}
          <Button type="submit" variant="contained" color='primary' sx={{ mt: 2 }}>{t('submit')}</Button>
        </Box>
      </Card>
    </Container>
  );
};

export default FormComponent;
