import balconyRenovation from '../resources/Balcony Renovations/pic_cover.jpg'
import balconyRenovation2 from '../resources/Balcony Renovations/pic_2.jpg'

import pic_cover_Garden from '../resources/Garden renovations/pic_cover.jpg'
import pic_1_Garden from '../resources/Garden renovations/pic_1.jpg'
import pic_2_Garden from '../resources/Garden renovations/pic_2.jpg'
import pic_3_Garden from '../resources/Garden renovations/pic_3.jpg'
import pic_4_Garden from '../resources/Garden renovations/pic_4.jpg'

import pic_cover_roof from '../resources/Complete Roof renovation/pic_cover.jpg'
import pic_1_Roof from '../resources/Complete Roof renovation/pic_1.jpg'
import pic_2_Roof from '../resources/Complete Roof renovation/pic_2.jpg'
import pic_3_Roof from '../resources/Complete Roof renovation/pic_3.jpg'
import pic_4_Roof from '../resources/Complete Roof renovation/pic_4.jpg'
import pic_5_Roof from '../resources/Complete Roof renovation/pic_5.jpg'
import pic_6_Roof from '../resources/Complete Roof renovation/pic_6.jpeg'
import pic_7_Roof from '../resources/Complete Roof renovation/pic_7.jpeg'
import pic_8_Roof from '../resources/Complete Roof renovation/pic_8.jpeg'
import pic_9_Roof from '../resources/Complete Roof renovation/pic_9.jpeg'
import pic_10_Roof from '../resources/Complete Roof renovation/pic_10.jpeg'
import pic_11_Roof from '../resources/Complete Roof renovation/pic_11.jpeg'
import pic_12_Roof from '../resources/Complete Roof renovation/pic_12.jpeg'
import pic_13_Roof from '../resources/Complete Roof renovation/pic_13.jpeg'
import pic_14_Roof from '../resources/Complete Roof renovation/pic_14.jpeg'

import pic_cover_bath from '../resources/Bathroom/pic_1_cover.jpeg'
import pic_2_bath from '../resources/Bathroom/pic_2.jpeg'
import pic_3_bath from '../resources/Bathroom/pic_3.jpeg'
import pic_4_bath from '../resources/Bathroom/pic_4.jpeg'
import pic_5_bath from '../resources/Bathroom/pic_5.jpeg'
import pic_6_bath from '../resources/Bathroom/pic_6.jpeg'
import pic_7_bath from '../resources/Bathroom/pic_7.jpeg'

import pic_cover_decroof from '../resources/Decorative Roof/pic_1.jpeg'
import pic_2_decroof from '../resources/Decorative Roof/pic_2.jpeg'
import pic_3_decroof from '../resources/Decorative Roof/pic_3.jpeg'

import pic_cover_facade from '../resources/Facade Renovation/pic_1.jpg'
import pic_2_facade from '../resources/Facade Renovation/pic_2.jpg'
import pic_3_facade from '../resources/Facade Renovation/pic_3.jpg'
import pic_4_facade from '../resources/Facade Renovation/pic_4.jpg'
import pic_5_facade from '../resources/Facade Renovation/pic_5.jpg'
import pic_6_facade from '../resources/Facade Renovation/pic_6.jpg'
import pic_7_facade from '../resources/Facade Renovation/pic_7.jpeg'
import pic_8_facade from '../resources/Facade Renovation/pic_8.jpeg'

import pic_cover_floor from '../resources/flooring/pic_1_cover.jpeg'
import pic_2_floor from '../resources/flooring/pic_2.jpeg'
import pic_3_floor from '../resources/flooring/pic_3.jpeg'
import pic_4_floor from '../resources/flooring/pic_4.jpeg'
import pic_5_floor from '../resources/flooring/pic_5.jpeg'
import pic_6_floor from '../resources/flooring/pic_6.jpeg'
import pic_7_floor from '../resources/flooring/pic_7.jpeg'
import pic_8_floor from '../resources/flooring/pic_8.jpeg'

import pic_cover_pavement from '../resources/Pavement/pic_1.jpg'
import pic_2_pavement from '../resources/Pavement/pic_2.jpg'
import pic_3_pavement from '../resources/Pavement/pic_3.jpg'
import pic_4_pavement from '../resources/Pavement/pic_4.jpg'
import pic_5_pavement from '../resources/Pavement/pic_5.jpeg'
import pic_6_pavement from '../resources/Pavement/pic_6.jpeg'
import pic_7_pavement from '../resources/Pavement/pic_7.jpeg'
import pic_8_pavement from '../resources/Pavement/pic_8.jpeg'

import pic_cover_solar from '../resources/Roofing with solar panels/pic_cover.jpg'
import pic_2_solar from '../resources/Roofing with solar panels/pic_1.jpg'
import pic_3_solar from '../resources/Roofing with solar panels/pic_2.jpg'
import pic_4_solar from '../resources/Roofing with solar panels/pic_3.jpeg'
import pic_5_solar from '../resources/Roofing with solar panels/pic_4.jpeg'
import pic_6_solar from '../resources/Roofing with solar panels/pic_5.jpeg'
import pic_7_solar from '../resources/Roofing with solar panels/pic_6.jpeg'

import pic_cover_stairs from '../resources/Structural and Stairs/pic_cover.jpeg'
import pic_1_stairs from '../resources/Structural and Stairs/pic_1.jpeg'
import pic_2_stairs from '../resources/Structural and Stairs/pic_2.jpeg'
import pic_3_stairs from '../resources/Structural and Stairs/pic_3.jpeg'
import pic_4_stairs from '../resources/Structural and Stairs/pic_4.jpeg'
import pic_5_stairs from '../resources/Structural and Stairs/pic_5.jpeg'
import pic_6_stairs from '../resources/Structural and Stairs/pic_6.jpeg'
import pic_7_stairs from '../resources/Structural and Stairs/pic_7.jpeg'
import pic_8_stairs from '../resources/Structural and Stairs/pic_8.jpeg'
import pic_9_stairs from '../resources/Structural and Stairs/pic_9.jpeg'
import pic_10_stairs from '../resources/Structural and Stairs/pic_10.jpeg'
import pic_11_stairs from '../resources/Structural and Stairs/pic_11.jpeg'
import pic_12_stairs from '../resources/Structural and Stairs/pic_12.jpeg'
import pic_13_stairs from '../resources/Structural and Stairs/pic_13.jpeg'
import pic_14_stairs from '../resources/Structural and Stairs/pic_14.jpeg'
import pic_15_stairs from '../resources/Structural and Stairs/pic_15.jpeg'
import pic_16_stairs from '../resources/Structural and Stairs/pic_16.jpeg'

import pic_cover_wall from '../resources/Wall/pic_1.jpeg'
import pic_1_wall from '../resources/Wall/pic_2.jpeg'

import dia_video from '../resources/Structural and Stairs/diamond_cutter.mp4'
import app_video from '../resources/whole appartment/appartment.mp4'
import app_cover from '../resources/whole appartment/cover.png'


export interface ProjectModalData {
  image: string;
  text: string;
  video?: boolean
  sole_video?: boolean
}

export interface ProjectCardData {
  image: string;
  desc: string;
  title: string;
  projectModalData: ProjectModalData[];
}

class ProjectStaticData {
  static mainObjects: ProjectCardData[] = [
    { //Balcony
      image: balconyRenovation,
      desc: 'renovations.balcony.desc',
      title: 'renovations.balcony.title',
      projectModalData: [
        {
          image: balconyRenovation,
          text: 'renovations.balcony.modal.text1'
        },
        {
          image: balconyRenovation2,
          text: 'renovations.balcony.modal.text2'
        }
      ]
    },
    { //Garden
      image: pic_cover_Garden,
      desc: 'renovations.garden.desc',
      title: 'renovations.garden.title',
      projectModalData: [
        {
          image: pic_1_Garden,
          text: 'renovations.garden.modal.text1'
        },
        {
          image: pic_2_Garden,
          text: 'renovations.garden.modal.text2'
        },
        {
          image: pic_3_Garden,
          text: 'renovations.garden.modal.text3'
        },
        {
          image: pic_4_Garden,
          text: 'renovations.garden.modal.text4'
        }
        // Add more secondary objects as needed
      ]
    },
    { //Roof
      image: pic_cover_roof,
      desc: 'renovations.roof.desc',
      title: 'renovations.roof.title',
      projectModalData: [
        {
          image: pic_1_Roof,
          text: 'renovations.roof.modal.text1'
        },
        {
          image: pic_2_Roof,
          text: 'renovations.roof.modal.text2'
        },
        {
          image: pic_3_Roof,
          text: 'renovations.roof.modal.text3'
        },
        {
          image: pic_4_Roof,
          text: 'renovations.roof.modal.text4'
        },
        {
          image: pic_5_Roof,
          text: 'renovations.roof.modal.text5'
        },
        {
          image: pic_6_Roof,
          text: 'renovations.roof.modal.text6'
        },
        {
          image: pic_7_Roof,
          text: 'renovations.roof.modal.text7'
        },
        {
          image: pic_8_Roof,
          text: 'renovations.roof.modal.text8'
        },
        {
          image: pic_9_Roof,
          text: 'renovations.roof.modal.text9'
        },
        {
          image: pic_10_Roof,
          text: 'renovations.roof.modal.text10'
        },
        {
          image: pic_11_Roof,
          text: 'renovations.roof.modal.text11'
        },
        {
          image: pic_12_Roof,
          text: 'renovations.roof.modal.text12'
        },
        {
          image: pic_13_Roof,
          text: 'renovations.roof.modal.text13'
        },
        {
          image: pic_14_Roof,
          text: 'renovations.roof.modal.text14'
        }
      ]
    },
    { //Bathroom
      image: pic_cover_bath,
      desc: 'renovations.bathroom.desc',
      title: 'renovations.bathroom.title',
      projectModalData: [
        {
          image: pic_2_bath,
          text: 'renovations.bathroom.modal.text1'
        },
        {
          image: pic_3_bath,
          text: 'renovations.bathroom.modal.text3'
        },
        {
          image: pic_4_bath,
          text: 'renovations.bathroom.modal.text4'
        },
        {
          image: pic_5_bath,
          text: 'renovations.bathroom.modal.text5'
        },
        {
          image: pic_6_bath,
          text: 'renovations.bathroom.modal.text6'
        },
        {
          image: pic_7_bath,
          text: 'renovations.bathroom.modal.text7'
        }
      ]
    },
    { // decroof
      image: pic_cover_decroof,
      desc: 'renovations.decroof.desc',
      title: 'renovations.decroof.title',
      projectModalData: [
        {
          image: pic_2_decroof,
          text: 'renovations.decroof.modal.text1'
        },
        {
          image: pic_3_decroof,
          text: 'renovations.decroof.modal.text2'
        }
      ]
    },
    { //Facade
      image: pic_cover_facade,
      desc: 'renovations.facade.desc',
      title: 'renovations.facade.title',
      projectModalData: [
        {
          image: pic_2_facade,
          text: 'renovations.facade.modal.text1'
        },
        {
          image: pic_3_facade,
          text: 'renovations.facade.modal.text2'
        },
        {
          image: pic_4_facade,
          text: 'renovations.facade.modal.text3'
        },
        {
          image: pic_5_facade,
          text: 'renovations.facade.modal.text4'
        },
        {
          image: pic_6_facade,
          text: 'renovations.facade.modal.text5'
        },
        {
          image: pic_7_facade,
          text: 'renovations.facade.modal.text6'
        },
        {
          image: pic_8_facade,
          text: 'renovations.facade.modal.text7'
        }
      ]
    },
    { //Floor
      image: pic_cover_floor,
      desc: 'renovations.floor.desc',
      title: 'renovations.floor.title',
      projectModalData: [
        {
          image: pic_2_floor,
          text: 'renovations.floor.modal.text1'
        },
        {
          image: pic_3_floor,
          text: 'renovations.floor.modal.text2'
        },
        {
          image: pic_4_floor,
          text: 'renovations.floor.modal.text3'
        },
        {
          image: pic_5_floor,
          text: 'renovations.floor.modal.text4'
        },
        {
          image: pic_6_floor,
          text: 'renovations.floor.modal.text5'
        },
        {
          image: pic_7_floor,
          text: 'renovations.floor.modal.text6'
        },
        {
          image: pic_8_floor,
          text: 'renovations.floor.modal.text7'
        }
      ]
    },
    { //Pavement
      image: pic_cover_pavement,
      desc: 'renovations.pavement.desc',
      title: 'renovations.pavement.title',
      projectModalData: [
        {
          image: pic_2_pavement,
          text: 'renovations.pavement.modal.text1'
        },
        {
          image: pic_3_pavement,
          text: 'renovations.pavement.modal.text2'
        }
        ,
        {
          image: pic_4_pavement,
          text: 'renovations.pavement.modal.text3'
        }
        ,
        {
          image: pic_5_pavement,
          text: 'renovations.pavement.modal.text4'
        }
        ,
        {
          image: pic_6_pavement,
          text: 'renovations.pavement.modal.text5'
        }
        ,
        {
          image: pic_7_pavement,
          text: 'renovations.pavement.modal.text6'
        }
        ,
        {
          image: pic_8_pavement,
          text: 'renovations.pavement.modal.text7'
        }
      ]
    },
    { //Solar roof
      image: pic_cover_solar,
      desc: 'renovations.solar.desc',
      title: 'renovations.solar.title',
      projectModalData: [
        {
          image: pic_2_solar,
          text: 'renovations.solar.modal.text1'
        },
        {
          image: pic_3_solar,
          text: 'renovations.solar.modal.text2'
        },
        {
          image: pic_4_solar,
          text: 'renovations.solar.modal.text3'
        },
        {
          image: pic_5_solar,
          text: 'renovations.solar.modal.text4'
        },
        {
          image: pic_6_solar,
          text: 'renovations.solar.modal.text5'
        },
        {
          image: pic_7_solar,
          text: 'renovations.solar.modal.text6'
        }
      ]
    },
    { //Structural and Walls
      image: pic_cover_stairs,
      desc: 'renovations.stairs.desc',
      title: 'renovations.stairs.title',
      projectModalData: [
        {
          image: dia_video,
          text: 'renovations.stairs.modal.text',
          video: true
        },
        {
          image: pic_1_stairs,
          text: 'renovations.stairs.modal.text1'
        },
        {
          image: pic_2_stairs,
          text: 'renovations.stairs.modal.text2'
        },
        {
          image: pic_3_stairs,
          text: 'renovations.stairs.modal.text3'
        },
        {
          image: pic_4_stairs,
          text: 'renovations.stairs.modal.text4'
        },
        {
          image: pic_5_stairs,
          text: 'renovations.stairs.modal.text5'
        },
        {
          image: pic_6_stairs,
          text: 'renovations.stairs.modal.text6'
        },
        {
          image: pic_7_stairs,
          text: 'renovations.stairs.modal.text7'
        },
        {
          image: pic_8_stairs,
          text: 'renovations.stairs.modal.text8'
        },
        {
          image: pic_9_stairs,
          text: 'renovations.stairs.modal.text9'
        },
        {
          image: pic_10_stairs,
          text: 'renovations.stairs.modal.text10'
        },
        {
          image: pic_11_stairs,
          text: 'renovations.stairs.modal.text11'
        },
        {
          image: pic_12_stairs,
          text: 'renovations.stairs.modal.text12'
        },
        {
          image: pic_13_stairs,
          text: 'renovations.stairs.modal.text13'
        },
        {
          image: pic_14_stairs,
          text: 'renovations.stairs.modal.text14'
        },
        {
          image: pic_15_stairs,
          text: 'renovations.stairs.modal.text15'
        },
        {
          image: pic_16_stairs,
          text: 'renovations.stairs.modal.text16'
        }
      ]
    },
    { // Wall
      image: pic_cover_wall,
      desc: 'renovations.wall.desc',
      title: 'renovations.wall.title',
      projectModalData: [
        {
          image: pic_cover_wall,
          text: 'renovations.wall.modal.text1'
        },
        {
          image: pic_1_wall,
          text: 'renovations.wall.modal.text2'
        }
      ]
    },
    {//Appartement
      image: app_cover,
      desc: 'renovations.appartement.desc',
      title: 'renovations.appartement.title',
      projectModalData: [
        {
          image: app_video,
          text: '',
          video: true,
          sole_video: true
        }
      ]
    }

  ];
}

export default ProjectStaticData;