import { Box, Button, ButtonGroup } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import en from '../resources/england.svg';
import it from '../resources/italy.svg';

const LanguageSwitcher: React.FC = () => {
  const { i18n } = useTranslation();

  const handleChange = (lang: string) => {
    i18n.changeLanguage(lang);
  };

  const buttons = [
    { lang: 'en', image: en },
    { lang: 'it', image: it },
  ];

  return (
    <Box
      sx={{
        display: 'flex',
        '& > *': {
          m: 1,
        },
      }}
    >
      <ButtonGroup orientation="vertical" aria-label="Vertical button group">
        {buttons.map((button) => (
          <Button
            key={button.lang}
            onClick={() => handleChange(button.lang)}
            variant={i18n.language === button.lang ? 'contained' : 'outlined'}
          >
            <img src={button.image} alt={button.lang} width="16px" />
          </Button>
        ))}
      </ButtonGroup>
    </Box>
  );
};

export default LanguageSwitcher;


/**
 <Select
      value={i18n.language}
      onChange={handleChange}
      variant="outlined"
      style={{backgroundColor: '#883447', textDecorationColor: '#2b2e38'}}
    >
      <MenuItem style={{backgroundColor: '#883447'}}value="en"><p style={{color: '#ffffff'}}>English <img src={en} alt='en' width='16px' /> </p></MenuItem>
      <MenuItem style={{backgroundColor: '#883447'}}value="it"><p style={{color: '#ffffff'}}>Italiano <img src={it} alt='it' width='16px' /></p> </MenuItem>
    </Select>
 */
